import { CSSProperties, useState } from "react";
import { Toggle } from '@cimpress/react-components';

export function ToggleOption(props: { label: string, selectedLayers: string[], setSelectedLayers: Function }) {
    const { label, selectedLayers, setSelectedLayers } = props;

    const toggle_option: CSSProperties = {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between'
    };
    
    const toggle_option_label: CSSProperties = {
        marginRight: '10px',
        fontWeight: 800,
    }

    function onToggleChange() {
        if (!selectedLayers.includes(label)) {
            const newSelected = [...selectedLayers];
            newSelected.push(label);
            setSelectedLayers(newSelected);
        } else {
            setSelectedLayers(selectedLayers.filter(selected => selected !== label));
        }
    };

    return (<>
        <div style={toggle_option}>
            <div style={toggle_option_label}>{label}</div>
            <Toggle on={selectedLayers.includes(label)} onClick={onToggleChange} size={'sm'} />
        </div>
    </>);
}