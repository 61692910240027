import axios, { AxiosRequestConfig } from 'axios';

export function makeAxiosRequest(url: string, method: string, data: any, header?: any, authToken?: string): Promise<any> {
    const headers = {
        ...header,
        'Content-Type': 'application/json',
    };

    if (authToken) {
        headers.Authorization = `Bearer ${authToken}`;
    }

    return axios({ url, method, data, headers } as AxiosRequestConfig);
}

export function makePublicServiceRequest(url: string, method: string = 'get', data?: any): Promise<any> {
    return makeAxiosRequest(url, method, data);
}
