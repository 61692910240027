import { UPLOADS_ENDPOINT } from "./constant";
import { makePublicServiceRequest } from "./servicesClient";

async function createUploadsAsset(formData: FormData) {
    const resp = await  makePublicServiceRequest(`${UPLOADS_ENDPOINT}?tenant=default`, 'post', formData);
    return `${UPLOADS_ENDPOINT}/${resp.data[0].uploadId}?tenant=default`
}

export function uploadImage(file: File) {
    const formData = new FormData();
    formData.append('file', file, file.name);

    return createUploadsAsset(formData);
}