import { Circle } from "react-konva";
import { Point } from "./types";

const cursorStyles = ['nw-resize', 'ne-resize', 'se-resize', 'sw-resize'];

export function GrabPoints(props: { points: Point[], onDragStart: Function, onDragMove: Function, onDragEnd: Function, offset: number, width?: number, height?: number, color?: string }) {
    const { points, width, height, offset, color } = props;

    function onDragStart(e: any, index: number) {
        const initialPosition = {
            x: e.evt.clientX,
            y: e.evt.clientY,
        };

        document.onmousemove = (e) => onDragMove(e, initialPosition, index);
        document.onmouseup = onDragEnd;

        e.cancelBubble = true;
        props.onDragStart();
    }

    function onDragMove(e: any, initialPosition: Point, index: number) {
        const deltaX = (initialPosition.x - e.clientX);
        const deltaY = (initialPosition.y - e.clientY);

        const selected = points[index];
        const newPoints = points.map(point => {
            const newPoint = { ...point };

            // setting points to new coordinates
            if (point.x === selected.x) {
                // restrict it to be within width bounds
                newPoint.x = width ? Math.min(Math.max(offset, point.x - deltaX), width + offset) : point.x - deltaX;
            }
            if (point.y === selected.y) {
                // restrict it to be within height bounds
                newPoint.y = height ? Math.min(Math.max(offset, point.y - deltaY), height + offset) : point.y - deltaY;
            }
            return newPoint;
        });

        props.onDragMove([newPoints[0], newPoints[2]]);
    }

    function onDragEnd() {
        document.onmousemove = () => { };
        document.onmouseup = () => { };

        props.onDragEnd();
    }

    function cursorChange(cursorStyle: string) {
        document.body.style.cursor = cursorStyle;
    }

    return (<>
        {points.map((point, index) => {
            return <Circle
                key={`${point.x},${point.y}`}
                x={point.x}
                y={point.y}
                radius={10}
                fill={color || 'black'}
                stroke={'white'}
                strokeWidth={1}
                onMouseEnter={() => cursorChange(cursorStyles[index])}
                onMouseLeave={() => cursorChange('default')}
                onMouseDown={e => onDragStart(e, index)}
            />
        })}
    </>);
}