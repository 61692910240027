import { makePublicServiceRequest } from "../clients";
import { TemplateSceneData } from "../components/templateSceneSelector";

enum ElementType {
    Image = 'Image',
    Composite = 'Composite'
}

export async function getTemplateSceneData(templateSceneUrl: string): Promise<TemplateSceneData> {
    const xml = await parseXml(templateSceneUrl);

    const elementNames = getImageElementNames(xml);
    const backgroundName = getBackgroundImageElementName(xml);
    const size = getSize(xml);

    return {
        url: templateSceneUrl,
        layers: elementNames,
        background: backgroundName,
        width: parseInt(size.width),
        height: parseInt(size.height)
    }
}

async function parseXml(templateSceneUrl: string) {
    const response = await makePublicServiceRequest(templateSceneUrl);

    const parser = new DOMParser();
    return parser.parseFromString(response.data, "text/xml");
}

function getImageElementNames(xml: Document): string[] {
    const elementNames: string[] = [];

    const imageElements = recursivelyFindAllElement(xml.documentElement, ElementType.Image);
    for (const ele of imageElements) {
        const name = ele.attributes.getNamedItem('name')?.value;
        if (name) {
            elementNames.push(name);
        }
    }

    return elementNames;
}

function getBackgroundImageElementName(xml: Document) {
    let background: string = '';
    let maxDepth = -1;

    const imageElements = recursivelyFindAllElement(xml.documentElement, ElementType.Image);
    for (const ele of imageElements) {
        const name = ele.attributes.getNamedItem('name')?.value;
        const depth = ele.attributes.getNamedItem('depth')?.value;

        if (depth && name && parseInt(depth) > maxDepth) {
            maxDepth = parseInt(depth);
            background = name;
        }
    }

    return background;
}

// return size of first found Composite element
function getSize(xml: Document) {
    const rootComposite = recursivelyFindFirstElement(xml.documentElement, ElementType.Composite);
    const size = rootComposite?.attributes.getNamedItem('size')?.value.split(',');
    return { width: size?.[0] || '0', height: size?.[1] || '0' };
}

function recursivelyFindAllElement(element: Element, nodeName: string) {
    let elementArray: Element[] = [];

    for (const ele of element.children) {
        if (ele.nodeName === nodeName) {
            elementArray.push(ele);
        }

        if (ele.children.length > 0) {
            elementArray = elementArray.concat(recursivelyFindAllElement(ele, nodeName));
        }
    }

    return elementArray;
}

function recursivelyFindFirstElement(element: Element, nodeName: string): Element | undefined {
    let firstElement;

    for (const ele of element.children) {
        if (ele.nodeName === nodeName) {
            return ele;
        } else if (ele.children.length > 0 && !firstElement) {
            firstElement = recursivelyFindFirstElement(ele, nodeName);
        }
    }

    return firstElement;
}