import { ASSET_ENDPOINT } from "../../clients/constant";
import { CropBounds } from "../../components/cropOptionSelector";

interface CompositionOperation {
    operationType: OperationType,
    elementName?: string,
    assetUrl?: string,
    cropBounds?: {
        width: number,
        height: number,
        topX: number,
        topY: number
    }
}

enum OperationType {
    Crop = 'Crop',
    Remove = 'Remove',
    Replace = 'Replace'
}

export function getSceneCompositionUrl(sceneUrl: string, unselectedLayers: string[], backgroundLayer: string, background?: string, cropBounds?: CropBounds) {
    const operations: CompositionOperation[] = [];

    if (cropBounds) {
        operations.push(createCropOperation(cropBounds));
    }

    // background is a selected layer and a background image is provided
    if (!unselectedLayers.includes(backgroundLayer) && background) {
        operations.push(createReplaceOperation(backgroundLayer, background));
    }

    unselectedLayers.forEach(layer => {
        operations.push(createRemoveOperation(layer));
    });

    return `${ASSET_ENDPOINT}compose?sceneUrl=${encodeURIComponent(sceneUrl)}&compositionOperations=${encodeURIComponent(JSON.stringify(operations))}`;
}

function createCropOperation(cropBounds: CropBounds): CompositionOperation {
    return {
        operationType: OperationType.Crop,
        cropBounds: {
            width: cropBounds.size.width,
            height: cropBounds.size.height,
            topX: cropBounds.cornerPoint.x,
            topY: cropBounds.cornerPoint.y
        }
    }
}

function createReplaceOperation(name: string, background: string): CompositionOperation {
    return {
        operationType: OperationType.Replace,
        elementName: name,
        assetUrl: background
    }
}

function createRemoveOperation(name: string): CompositionOperation {
    return {
        operationType: OperationType.Remove,
        elementName: name
    }
}
