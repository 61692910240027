import './App.css';
import { CssLoader } from '@cimpress/react-components';
import { Demo } from './Demo';

function App() {
  return (
    <CssLoader>
      <Demo />
    </CssLoader>
  );
}

export default App;
