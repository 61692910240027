import { INSTRUCTIONS_ENDPOINT } from "../../clients/constant";
import { makePublicServiceRequest } from "../../clients/servicesClient";

function getParam(documentUrlParams: URLSearchParams, paramName: string) {
    for (let [key, value] of documentUrlParams.entries()) {
        if (key.toLowerCase() === paramName) {
            return value;
        }
    }
    return undefined;
}

export async function getRenderingInstructions(document: string) {
    // if url is cim.press url get original url
    if (document.includes("cim.press")) {
        const response = await makePublicServiceRequest(document);
        document = response.request.responseURL;
    }

    const documentUrlParams = new URLSearchParams(document.substring(document.indexOf('?')));

    // get instructions url from query param: urls with rendering instruction parameters
    const instructionsUrl = getParam(documentUrlParams, 'instructions_uri');

    if (instructionsUrl) {
        return instructionsUrl;
    }

    const response = await makePublicServiceRequest(document);
    const documentContent = response.data;

    if (documentContent.Slots !== undefined) { // is rending instructions url
        return document;
    } else if (documentContent.renderingInstructionsUrl !== undefined) { // is document reference url
        return documentContent.renderingInstructionsUrl;
    } else { // is cimpress document url
        return `${INSTRUCTIONS_ENDPOINT}/v3/instructions:preview?documentUri=${encodeURIComponent(document)}`;
    }

}