import { Card, TextField, Button } from "@cimpress/react-components";
import { CSSProperties, useEffect, useState } from "react";
import { Point } from "../canvas/types";
import { Size } from "./types";

export function CropOptionSelector(props: { points: Point[], setPoints: Function, sceneSize: Size, onApply: Function, onCancel: Function }) {
    const { points, setPoints, sceneSize, onApply, onCancel } = props;

    const [width, setWidth] = useState<number | undefined>();
    const [height, setHeight] = useState<number | undefined>();
    const [x, setX] = useState<number | undefined>();
    const [y, setY] = useState<number | undefined>();

    const [errorMessage, setErrorMessage] = useState<string>();

    const row: CSSProperties = {
        display: 'flex',
        width: '100%'
    };

    const labelStyle: CSSProperties = {
        marginBottom: '5px',
    };

    useEffect(() => {
        setWidth(Math.abs(points[0].x - points[1].x));
        setHeight(Math.abs(points[0].y - points[1].y));
        setX(Math.min(points[0].x, points[1].x));
        setY(Math.min(points[0].y, points[1].y));
    }, [points]);

    useEffect(() => {
        if (width && width > 0) {
            // cannot be bigger than original scene size
            if (width > sceneSize.width) {
                setErrorMessage(`The width cannot be greater than scene\'s original width which is ${sceneSize.width}`);
                return;
            }

            const newPoints = [...points];
            newPoints[1].x = newPoints[0].x + width;
            if (newPoints[1].x > sceneSize.width) {
                newPoints[1].x = sceneSize.width;
                newPoints[0].x = sceneSize.width - width;
            }

            setPoints(newPoints);

            setErrorMessage('');
            return;
        }
        setErrorMessage('The width need to be defined and needs to be greater than 0');
    }, [width]);

    useEffect(() => {
        if (height && height > 0) {
            // cannot be bigger than original scene size
            if (height > sceneSize.height) {
                setErrorMessage(`The height cannot be greater than scene\'s original height which is ${sceneSize.height}`);
                return;
            }

            const newPoints = [...points];
            newPoints[1].y = newPoints[0].y + height;
            if (newPoints[1].y > sceneSize.height) {
                newPoints[1].y = sceneSize.height;
                newPoints[0].y = sceneSize.height - height;
            }

            setPoints(newPoints);

            setErrorMessage('');
            return;
        }
        setErrorMessage('The height need to be defined and needs to be greater than 0');
    }, [height]);

    useEffect(() => {
        if (width && x !== undefined && x >= 0) {
            const maxX = sceneSize.width - width;
            if (x > maxX) {
                setErrorMessage(`X has to be between 0 and ${maxX}`);
                return;
            }

            const newPoints = [...points];
            newPoints[0].x = x;
            newPoints[1].x = x + width;

            setPoints(newPoints);

            setErrorMessage('');
            return;
        }
        setErrorMessage('X need to be defined and needs to be greater or equal to 0');
    }, [x]);

    useEffect(() => {
        if (height && y !== undefined && y >= 0) {
            const maxY = sceneSize.height - height;
            if (y > maxY) {
                setErrorMessage(`Y has to be between 0 and ${maxY}`);
                return;
            }

            const newPoints = [...points];
            newPoints[0].y = y;
            newPoints[1].y = y + height;

            setPoints(newPoints);

            setErrorMessage('');
            return;
        }
        setErrorMessage('Y need to be defined and needs to be greater or equal to 0');
    }, [y]);

    return (<>
        <Card isMinimal>
            <div style={labelStyle}>Crop Size</div>
            <div style={row}>
                <TextField
                    label='width'
                    type='number'
                    value={width}
                    onChange={(e) => setWidth(parseInt(e.target.value))}
                    style={{ marginRight: '10px' }}
                    id='crop width'
                />
                <TextField
                    label='height'
                    type='number'
                    value={height}
                    onChange={(e) => setHeight(parseInt(e.target.value))}
                    id='crop height'
                />
            </div>

            <div style={labelStyle}>Top Left Point</div>
            <div style={row}>
                <TextField
                    label='x'
                    type='number'
                    value={x}
                    onChange={(e) => setX(parseInt(e.target.value))}
                    style={{ marginRight: '10px' }}
                    id='crop x'
                />
                <TextField
                    label='y'
                    type='number'
                    value={y}
                    onChange={(e) => setY(parseInt(e.target.value))}
                    id='crop y'
                />
            </div>
            <div style={{ color: 'red', width: '250px', float: 'left' }}>{errorMessage}</div>
            <Button variant="primary" style={{ float: 'right' }} onClick={() => onApply()}>Apply</Button>
            <Button variant="default" style={{ float: 'right', marginRight: '10px' }} onClick={() => onCancel()}>Cancel</Button>
        </Card>
    </>);
}