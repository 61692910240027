import { Card } from "@cimpress/react-components";
import { CSSProperties, useState } from "react";

export function BackgroundColorSelector(props: { color: string | undefined, setColor: Function }) {
    const [selectedColor, setSelectedColor] = useState<string>(props.color || '#FFFFFF');

    const colorPickerStyle: CSSProperties = {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between'
    };

    const colorOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let color = e.target.value;
        setSelectedColor(color);
        props.setColor(color);
    }

    return (<>
        <Card isMinimal>
            <div style={colorPickerStyle}>
                <div style={{marginRight: '10px'}}>Background Color</div>
                <input value={selectedColor} onChange={colorOnChange} type='color' data-testid='colorInput'/>
            </div>
        </Card>
    </>);
}