import { useEffect, useMemo, useState } from 'react';
import { Stage, Layer, Group, Rect } from 'react-konva';
import { Border } from './border';
import { GrabPoints } from './grabPoints';
import { Point } from './types';

const OFFSET = 20;
const COLOR = '#b1b1b1'

export function Canvas(props: { width: number, height: number, points: Point[], setPoints: Function, scale: number }) {
    const { width, height, points, setPoints, scale } = props;

    const [dragging, setDragging] = useState<boolean>(false);
    const [scaledPoints, setScaledPoints] = useState<Point[]>([
        { x: scaleValue(points[0].x), y: scaleValue(points[0].y) },
        { x: scaleValue(points[1].x), y: scaleValue(points[1].y) }
    ]);

    const rectanglePoints = [
        scaledPoints[0],
        { x: scaledPoints[1].x, y: scaledPoints[0].y },
        scaledPoints[1],
        { x: scaledPoints[0].x, y: scaledPoints[1].y }
    ];

    function scaleValue(value: number) {
        return value * scale + OFFSET;
    }

    function reverseValue(value: number) {
        return Math.round((value - OFFSET) / scale);
    }

    useEffect(() => {
        // scale points to match display size
        setScaledPoints([
            { x: scaleValue(points[0].x), y: scaleValue(points[0].y) },
            { x: scaleValue(points[1].x), y: scaleValue(points[1].y) }
        ])
    }, [points, scale]);

    useEffect(() => {
        if (!dragging) {
            // scale points back to match scene size
            setPoints([
                { x: reverseValue(scaledPoints[0].x), y: reverseValue(scaledPoints[0].y) },
                { x: reverseValue(scaledPoints[1].x), y: reverseValue(scaledPoints[1].y) }
            ]);
        }
    }, [dragging])

    function onDragStart() {
        setDragging(true);
    }

    function onDragMove(points: Point[]) {
        setScaledPoints(points);
    }

    function onDragEnd() {
        setDragging(false);
    }

    function clip(ctx: any) {
        ctx.beginPath();
        ctx.moveTo(0, 0);
        ctx.lineTo(width + OFFSET * 2, 0);
        ctx.lineTo(width + OFFSET * 2, height + OFFSET * 2);
        ctx.lineTo(0, height + OFFSET * 2);
        ctx.moveTo(scaledPoints[0].x, scaledPoints[1].y);
        ctx.lineTo(scaledPoints[1].x, scaledPoints[1].y);
        ctx.lineTo(scaledPoints[1].x, scaledPoints[0].y);
        ctx.lineTo(scaledPoints[0].x, scaledPoints[0].y);
        ctx.closePath();
    }

    return (<>
        <Stage width={width + OFFSET * 2} height={height + OFFSET * 2}>
            <Layer>
                <Group>
                    <Group clipFunc={clip} >
                        <Rect
                            x={OFFSET - 1}
                            y={OFFSET - 1}
                            width={width + 2}
                            height={height + 1}
                            fill={'black'}
                            opacity={0.5}
                        />
                    </Group>
                    <Border
                        points={scaledPoints}
                        rectanglePoints={rectanglePoints}
                        onDragStart={onDragStart}
                        onDragMove={onDragMove}
                        onDragEnd={onDragEnd}
                        width={width}
                        height={height}
                        offset={OFFSET}
                        color={COLOR}
                    />
                    <Group>
                        <GrabPoints
                            points={rectanglePoints}
                            onDragStart={onDragStart}
                            onDragMove={onDragMove}
                            onDragEnd={onDragEnd}
                            width={width}
                            height={height}
                            offset={OFFSET}
                            color={COLOR}
                        />
                    </Group>
                </Group>
            </Layer>
        </Stage>
    </>);
}