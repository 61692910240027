import { useState } from 'react';
import { Card, Button, TextField } from '@cimpress/react-components';
import { getRenderingInstructions } from '../../utility';

export function DocumentSelector(props: { setRenderingInstructions: Function, setLoading: Function }) {
    const [docUrl, setDocUrl] = useState<string>();

    return (<>
        <Card isMinimal>
            <TextField
                id='documentUrl'
                name='docUrl'
                label="Document Url"
                value={docUrl}
                onChange={e => { setDocUrl(e.currentTarget.value) }}
                onKeyDown={async e => {
                    if (e.key === 'Enter' && docUrl !== undefined && docUrl.length > 0) {
                        props.setLoading(true);
                        props.setRenderingInstructions(await getRenderingInstructions(docUrl));
                        props.setLoading(false);
                    }
                }}
            />
            <Button disabled={(docUrl === undefined || docUrl.length === 0)}
                onClick={async () => {
                    if (docUrl !== undefined && docUrl.length > 0) {
                        props.setLoading(true);
                        props.setRenderingInstructions(await getRenderingInstructions(docUrl));
                        props.setLoading(false);
                    }
                }}>Load</Button>
        </Card>
    </>);
}