import { Card, Button, Snackbar } from "@cimpress/react-components";
import { useRef, useState } from "react";
import { uploadImage } from "../../clients";
import { shortenImageName } from "../../utility";
import { BackgroundInfo } from "./types";

export function BackgroundImageSelector(props: { background: BackgroundInfo | undefined, setBackground: Function, setLoading: Function }) {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [name, setName] = useState<string | undefined>(props.background?.name);

    const [showError, setShowError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>();

    function openFileExplorer() {
        fileInputRef.current?.click();
    }

    function uploadFileToSherbert(event: React.ChangeEvent<HTMLInputElement>) {
        const fileUploaded = event.target.files?.[0];

        if (fileUploaded) {
            props.setLoading(true);

            uploadImage(fileUploaded).then(url => {
                props.setLoading(false);
                const name = fileUploaded.name.toLowerCase();

                setName(name);
                props.setBackground({ url, name })
            }).catch(error => {
                props.setLoading(false);
                setShowError(true);
                setErrorMessage(error.message);
            });
        }
    }

    return (<>
        <Snackbar show={showError} status={'danger'} delay={2000} onHideSnackbar={() => setShowError(false)}>
            {errorMessage}
        </Snackbar>
        <Card isMinimal>
            <input
                type='file'
                ref={fileInputRef}
                onChange={uploadFileToSherbert}
                style={{ display: 'none' }}
                accept="image/*"
                data-testid="uploadInput"
            />
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button type='link' size='sm' onClick={openFileExplorer} >Upload Image</Button>
                <div style={{ marginLeft: '10px' }}>{shortenImageName(25, name)}</div>
            </div>
        </Card>
    </>);
}