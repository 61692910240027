import { useMemo } from 'react';
import { Line } from 'react-konva';
import { Point } from './types';

export function Border(props: { points: Point[], rectanglePoints: Point[], onDragStart: Function, onDragMove: Function, onDragEnd: Function, offset: number, width?: number, height?: number, color?: string }) {
    const { points, width, height, offset, color, rectanglePoints } = props;

    const reducedPoints = useMemo(() => rectanglePoints.reduce((array: number[], point: Point) => array.concat([point.x, point.y]), []), [rectanglePoints]);

    function onMouseDown(e: any) {
        onDragStart(e);
    }

    function onDragStart(e: any) {
        const initialPosition = {
            x: e.evt.clientX,
            y: e.evt.clientY,
        };

        document.onmousemove = (e) => onDragMove(e, initialPosition);
        document.onmouseup = onDragEnd;

        e.cancelBubble = true;
        props.onDragStart();
    }

    function onDragMove(e: any, initialPosition: Point) {
        const deltaX = (initialPosition.x - e.clientX);
        const deltaY = (initialPosition.y - e.clientY);

        const newPoints = points.map(point => ({ x: point.x - deltaX, y: point.y - deltaY }));

        if (width) {
            // ensure point never goes outside of width bounds
            const boxWidth = newPoints[1].x - newPoints[0].x;
            const rightBound = width + offset;

            if (newPoints[1].x > rightBound) {
                newPoints[0].x = rightBound - boxWidth;
                newPoints[1].x = rightBound;
            } else if (newPoints[0].x < offset) {
                newPoints[0].x = offset;
                newPoints[1].x = offset + boxWidth;
            }
        }

        if (height) {
            // ensure point never goes outside of height bounds
            const boxHeight = newPoints[1].y - newPoints[0].y;
            const bottomBound = height + offset;
            if (newPoints[1].y > bottomBound) {
                newPoints[0].y = bottomBound - boxHeight;
                newPoints[1].y = bottomBound;
            } else if (newPoints[0].y < offset) {
                newPoints[0].y = offset;
                newPoints[1].y = offset + boxHeight;
            }
        }

        props.onDragMove(newPoints);
    }

    function onDragEnd() {
        document.onmousemove = () => { };
        document.onmouseup = () => { };

        props.onDragEnd();
    }

    return (<>
        <Line
            points={reducedPoints}
            stroke={color || 'black'}
            strokeWidth={1}
            closed={true}
            onMouseDown={onMouseDown}
        />
    </>);
}