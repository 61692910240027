import { useEffect, useMemo, useRef, useState } from 'react';
import { Button, CopyButton, shapes, Snackbar } from '@cimpress/react-components';
import './Demo.css';

// @ts-ignore
import Header from '@cimpress-technology/react-platform-header';

import { BackgroundColorSelector, BackgroundImageSelector, BackgroundInfo } from './components/backgroundSelector';
import { CropBounds, CropOptionSelector, Size } from './components/cropOptionSelector';
import { DocumentSelector } from './components/documentSelector';
import { ToggleOption } from './components/toggleOption';

import { getSceneCompositionUrl, getRenderingUrl } from './utility';
import { TemplateSceneData, TemplateSceneSelector } from './components/templateSceneSelector';
import { makePublicServiceRequest } from './clients';
import { Canvas } from './components/canvas/canvas';
import { Point } from './components/canvas/types';

const CROP = 'crop';

export function Demo() {
    const { Spinner } = shapes;

    const imgRef = useRef<HTMLImageElement | null>(null);

    const [renderingInstructions, setRenderingInstructions] = useState<string>('https://instructions.documents.cimpress.io/v2/transient/deflate?document=7VRNi9swFDw7v8Lo1MIqlvwVx1AKPRR66GWhp9KDIj0lYm3LSEq2IaS%2FvbKTTbL5aDewKS00F8dvBr3RzPNbheEgDEO0AGOVblCJ4iFBd31N6sbdQ6utctosv5jKozPnWltGUYfZodB8XkP3j6u6NWDtUOmoNVpsj3gioHK1adQV7dxIxsGi8uuu6H%2Brw5eO17AafMuPxvfanLfHHpVwMw9SQkhdH6MzUNOZb4qyIj9FVc2mffdBEARnGm9prVGN29%2FaX3reVpqJS9de0CdClNE0T7gc44KnAqdsJPCExglOgBNgqaByMvpBKYneO2hY4975E3FbMSe1OZa7UwMLBY9%2Fj55uLPqJOW9fx%2FnupZ6ms8eXv8F%2FGfKedpT2Gdb6rovaZ420H%2FSKLS0Ky7DPPwhWm0dwbeBlQQ49HtGkAJpzHEuW4JRk3mPIYkzSmOaC0VSMee%2BxNmqqGlZd8Hon5sq8byuH60obr8S22r35DI5VleJv0Qbe2vvfyaudvGfKgvjUPOys3A7qt%2BMpXj8rHMC9%2BReX5wfGH26yO08%2F%2Btdfo4zIcQHjGOcyznAKowlmCSFY%2Bp014TSWUoo%2FuUZvoeefWqMvH8rBQWk9WP8E&type=preview');
    const [templateSceneData, setTemplateSceneData] = useState<TemplateSceneData>({
        url: 'https://assets.documents.cimpress.io/v3/assets/c9d53c2b-8097-4733-8330-c09bfbeee614/content',
        layers: ['screen', 'mask', 'product', 'drop shadow', 'background'],
        background: 'background',
        width: 1300,
        height: 1300
    });

    const [sceneUrlMap, setSceneUrlMap] = useState<{ [key: string]: string }>({});
    const [sceneUrl, setSceneUrl] = useState<string>('https://assets.documents.cimpress.io/v3/assets/c9d53c2b-8097-4733-8330-c09bfbeee614/content');
    const [selectedLayers, setSelectedLayers] = useState<string[]>(['screen', 'mask', 'product', 'drop shadow', 'background']);

    const [loading, setLoading] = useState<boolean>(false);

    const [background, setBackground] = useState<BackgroundInfo>();
    const [color, setColor] = useState<string>();

    const [cropOn, setCropOn] = useState<boolean>();
    const [crop, setCrop] = useState<CropBounds>();
    const [points, setPoints] = useState<Point[]>([
        { x: 0, y: 0 },
        { x: templateSceneData.width, y: templateSceneData.height }
    ]);

    const [canvasSize, setCanvasSize] = useState<Size>();

    const [showError, setShowError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>();

    function clearSceneMap(layer?: string) {
        // clear the entire mapping
        if (!layer) {
            setSceneUrlMap({});
            return;
        }

        // clear mapping with specified layer
        for (const scenePermutation in sceneUrlMap) {
            if (scenePermutation.includes(layer)) {
                delete sceneUrlMap[scenePermutation];
            }
        }
        setSceneUrlMap({ ...sceneUrlMap });
    }

    useEffect(() => {
        window.addEventListener('resize', () => {
            if (imgRef.current) {
                setCanvasSize({
                    width: imgRef.current.offsetWidth,
                    height: imgRef.current.offsetHeight
                });
            }
        });
    }, []);

    // clear entire scene url mapping
    useEffect(() => {
        if (templateSceneData) {
            clearSceneMap();
            setSelectedLayers(templateSceneData.layers);
            setSceneUrl(templateSceneData.url);
            setBackground(undefined);
            setCrop(undefined);
        }
    }, [templateSceneData]);

    // clear scene url mapping for background
    useEffect(() => {
        if (background) {
            clearSceneMap(templateSceneData.background);
        }
    }, [background]);

    // clear scene url mapping for crop
    useEffect(() => {
        if (crop) {
            clearSceneMap(CROP);
        }
    }, [crop]);

    useEffect(() => {
        const selectedLayersStr: string = [...selectedLayers].sort().join(',');
        if (!sceneUrlMap[selectedLayersStr]) {
            const unselected = templateSceneData.layers.filter(layer => !selectedLayers.includes(layer));
            const url = getSceneCompositionUrl(templateSceneData.url, unselected, templateSceneData.background, background?.url, selectedLayers.includes(CROP) ? crop : undefined);

            setSceneUrl(url);
            sceneUrlMap[selectedLayersStr] = url;

            setSceneUrlMap(sceneUrlMap);
        } else {
            setSceneUrl(sceneUrlMap[selectedLayersStr]);
        }

    }, [selectedLayers, sceneUrlMap]);

    function getBackgroundSelector() {
        if (selectedLayers.includes(templateSceneData.background)) {
            return <BackgroundImageSelector background={background} setBackground={setBackground} setLoading={setLoading} />
        }
        return <BackgroundColorSelector color={color} setColor={setColor} />
    }

    const previewUrl = useMemo(() => {
        setLoading(true);

        let renderingUrl: string;

        if (!selectedLayers.includes(templateSceneData.background) && color) {
            renderingUrl = getRenderingUrl(renderingInstructions, sceneUrl, color);
        } else {
            renderingUrl = getRenderingUrl(renderingInstructions, sceneUrl);
        }

        makePublicServiceRequest(renderingUrl).then(() => {
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            setShowError(true);
            setErrorMessage(err.message);
        });

        return renderingUrl;
    }, [selectedLayers, templateSceneData, color, renderingInstructions, sceneUrl]);

    function renderCropOption() {
        const onApply = () => {
            setCropOn(false);
            const newCrop: CropBounds = {
                size: {
                    width: Math.abs(points[0].x - points[1].x),
                    height: Math.abs(points[0].y - points[1].y)
                },
                cornerPoint: {
                    x: Math.min(points[0].x, points[1].x),
                    y: Math.min(points[0].y, points[1].y)
                }
            }

            setCrop(newCrop);
            setSelectedLayers([...selectedLayers, CROP]);
        }

        if (cropOn) {
            return (<>
                <div className='option_selector_label'>Crop Options</div>
                <CropOptionSelector
                    points={points}
                    setPoints={setPoints}
                    sceneSize={{ width: templateSceneData.width, height: templateSceneData.width }}
                    onApply={onApply}
                    onCancel={() => setCropOn(false)}
                />
            </>);
        }
        return (<Button variant='secondary' className='copyBtn' onClick={() => {
            setCropOn(true);
            setSelectedLayers(selectedLayers.filter(layer => layer !== CROP));
        }}>
            <img width={20} className='cropIcon' src='https://static.ux.cimpress.io/assets/icons/crop-area-l.svg' />
            Crop Scene
        </Button>);
    }

    return (<>
        <Header
            appTitle="Scene Composition Demo"
            onLogInClicked={() => { }}
            onLogOutClicked={() => { }}
            showLeftNav={false}
        />
        <div className='hide_login'></div>
        <Snackbar show={showError} status={'danger'} delay={2000} onHideSnackbar={() => setShowError(false)}>
            {errorMessage}
        </Snackbar>
        <div className="page">
            <div className="left">
                <div className='scene_preview'>
                    {loading && <div className='preview_spinner'>
                        <Spinner />
                    </div>}
                    <img src={previewUrl} ref={imgRef} onLoad={() => {
                        if (imgRef.current) {
                            setCanvasSize({
                                width: imgRef.current.offsetWidth,
                                height: imgRef.current.offsetHeight
                            });
                        }
                    }} />
                    {(cropOn && canvasSize) && <Canvas
                        width={canvasSize.width}
                        height={canvasSize.height}
                        points={points}
                        setPoints={setPoints}
                        scale={canvasSize.width / templateSceneData.width}
                    />}
                </div>
            </div>
            <div className="right">
                <div className='option_selector_label'>Scene Source</div>
                <TemplateSceneSelector setTemplateSceneUrl={setTemplateSceneData} setLoading={setLoading} />

                <div className='option_selector_label marginTop'>Layers</div>
                {templateSceneData.layers.map(layer => {
                    return <ToggleOption
                        key={layer}
                        label={layer}
                        selectedLayers={selectedLayers}
                        setSelectedLayers={setSelectedLayers}
                    />
                })}
                <ToggleOption
                    label={CROP}
                    selectedLayers={selectedLayers}
                    setSelectedLayers={setSelectedLayers}
                />

                <div className='option_selector_label marginTop'>Document Source</div>
                <DocumentSelector setRenderingInstructions={setRenderingInstructions} setLoading={setLoading} />

                <div className='option_selector_label'>Background Options</div>
                {getBackgroundSelector()}

                {renderCropOption()}

                <div className='copyBtn'>
                    <CopyButton variant='outline-secondary' value={previewUrl} successMessage="Rendering URL has been copied to clipboard!">Copy Rendering URL</CopyButton>
                    <CopyButton variant='outline-secondary' value={sceneUrl} successMessage="Scene URL has been copied to clipboard!">Copy Scene URL</CopyButton>
                </div>
            </div>
        </div>
    </>);
}