import { RENDERING_ENDPOINT } from "../../clients";

export function getRenderingUrl(renderingInstructions: string, scene: string, color?: string) {
    let url = `${RENDERING_ENDPOINT}?width=1000&instructions_uri=${encodeURIComponent(renderingInstructions)}&scene=${encodeURIComponent(scene)}`;

    if (color) {
        url += `&bgColor=${color.substring(1)}`;
    }

    return url;
}