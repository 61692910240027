import { useState } from 'react';
import { Card, Button, TextField } from '@cimpress/react-components';
import { getTemplateSceneData } from '../../utility';

export function TemplateSceneSelector(props: { setTemplateSceneUrl: Function, setLoading: Function }) {
    const [sceneUrl, setSceneUrl] = useState<string>();

    return (<>
        <Card isMinimal>
            <TextField
                id='templateSceneUrl'
                name='templateScene'
                label="Template Scene Url"
                value={sceneUrl}
                onChange={e => { setSceneUrl(e.currentTarget.value) }}
                onKeyDown={async e => {
                    if (e.key === 'Enter' && sceneUrl !== undefined && sceneUrl.length > 0) {
                        props.setLoading(true);
                        props.setTemplateSceneUrl(await getTemplateSceneData(sceneUrl));
                        props.setLoading(false);
                    }
                }}
            />
            <Button disabled={(sceneUrl === undefined || sceneUrl.length === 0)}
                onClick={async () => {
                    if (sceneUrl !== undefined && sceneUrl.length > 0) {
                        props.setLoading(true);
                        props.setTemplateSceneUrl(await getTemplateSceneData(sceneUrl));
                        props.setLoading(false);
                    }
                }}>Load</Button>
        </Card>
    </>);
}